import { Dropdown, Typography } from "@beckersdp/becker-ds";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { useUser } from "../../../../../../hooks";
import { useTranslation } from "../../../../../../i18n";
import { PageQALabels } from "../../../../../../page-objects/components/page/qa-labels";
import { useAppDispatch } from "../../../../../../store";
import { Cart } from "../../../../../../store/cart/thunks";
import { useHeaderUrls } from "../../use-header-urls";
import MiniCartToggle from "../mini-cart-toggle";
import { CustomDropdownMenu, CustomOption, DownChevronIcon, MenuText, NavOption } from "./styles";

export const AccountNavigation: FC = () => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null); // Popper's ref for mini cart
  const { user } = useUser();
  const { t } = useTranslation(["context_app"]);
  const { help, account } = useHeaderUrls();
  const dispatch = useAppDispatch();

  useEffect(() => {
    user.shoppingCart && dispatch(Cart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.shoppingCart]);

  return (
    <AccountNav>
      {help?.length > 0 && (
        <AccountLi>
          <Dropdown
            placement="bottom-start"
            data-qa-label={PageQALabels.helpDropdown}
            menu={
              <AccountHelp>
                {help.map((link, index: number) => (
                  <CustomOption
                    key={index}
                    data-qa-label={link.permission.toLowerCase().replace(" ", "")}
                    onClick={() => {
                      if (link?.external) {
                        window.open(link.url, "_blank");
                      } else {
                        window.location.href = link.url;
                      }
                    }}
                  >
                    <Typography.Text level="2" weight="bold">
                      {link.description}
                    </Typography.Text>
                  </CustomOption>
                ))}
              </AccountHelp>
            }
          >
            <NavOption>
              <MenuText style={{ height: "100%" }}>{t("context_app:help")}</MenuText>
              <DownChevronIcon name="chevron-down" />
            </NavOption>
          </Dropdown>
        </AccountLi>
      )}
      {user.shoppingCart && (
        <AccountLi>
          <MiniCartToggle setReferenceElement={setReferenceElement} referenceElement={referenceElement} />
        </AccountLi>
      )}
      <AccountLi>
        <Dropdown
          placement="bottom-start"
          data-qa-label={PageQALabels.accountDropdown}
          menu={
            <CustomMenu>
              {account.map((link, index: number) => (
                <CustomOption
                  key={index}
                  data-qa-label={link.name.toLowerCase().replace(" ", "")}
                  onClick={link.action}
                >
                  <Typography.Text level="2" weight="bold">
                    {link.name}
                  </Typography.Text>
                </CustomOption>
              ))}
            </CustomMenu>
          }
        >
          <NavOption>
            <MenuText style={{ height: "100%" }}>{user.username || user.email}</MenuText>
            <DownChevronIcon name="chevron-down" />
          </NavOption>
        </Dropdown>
      </AccountLi>
    </AccountNav>
  );
};

const AccountHelp = styled(CustomDropdownMenu)`
  width: 190px;
  top: 21px !important;
`;
const CustomMenu = styled(CustomDropdownMenu)`
  width: 150px;
  top: 21px !important;
`;

export const AccountLi = styled.li<{ active?: boolean }>`
  height: 66px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.brand01[700]};
  &:not(:first-child) {
    margin-left: -4px;
  }
  padding: 0 0 0 10px;
  height: 100%;
  cursor: pointer;
`;

export const AccountNav = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  height: 70px;
  height: calc(100% - 4px);
  position: relative;
  top: 2px;
  font-family: ${props => props.theme.fontFamily.primary};
  font-size: 14px;
  font-weight: 400;
  font-stretch: 100%;
  font-style: normal;
  font-variant-caps: normal;
  font-variant-east-asian: normal;
  font-variant-ligatures: normal;
  font-variant-numeric: normal;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
`;
