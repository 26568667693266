import { ProductLogo } from "@beckersdp/becker-ds";
import { FooterProps } from "@beckersdp/becker-ds/dist/templates/two-side-panel/footer";
import { UpdateDefaultPasswordTemplate } from "@beckersdp/becker-ds/dist/templates/update-default-password";
import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle } from "styled-components";
import { useTranslation } from "../../i18n";
import { footerLinks } from "../sign-in/components/common-template/footer-links";
import { UpdatePasswordForm } from "./update-password-form";

type Step = "forgot" | "emailSent" | "beckerRedirect" | "reset" | "resetSuccess";
type StepPanels = { [P in Step]?: JSX.Element };

const UpdateDefaultPassword: FC = () => {
  const { t } = useTranslation(["resetPassword"]);
  const [step, setStep] = useState<Step>("reset");

  const resetSubmit = async () => {
    setStep("resetSuccess");
  };
  const stepPanels: StepPanels = {
    reset: <StyledUpdatePasswordForm onSubmit={resetSubmit} />
  };
  const config = {
    mainPanelContent: stepPanels[step] as JSX.Element,
    logo: (
      <StyledProductLogo
        aria-label="CPE"
        Logo={require("@beckersdp/becker-ds/dist/assets/eds-images/logos/svg/cpe.svg")}
      />
    ),
    footer: footerLinks(t("resetPassword:copyrights")) as FooterProps,
    step
  };
  return (
    <>
      <Helmet>
        <title>{t("resetPassword:title")}</title>
      </Helmet>
      <StyledUpdatePasswordTemplate {...config} />
      <GlobalStyles />
    </>
  );
};

const StyledUpdatePasswordTemplate = styled(UpdateDefaultPasswordTemplate)`
  height: 100vh;
  overflow: hidden;
`;

const StyledUpdatePasswordForm = styled(UpdatePasswordForm)`
  width: 100%;
`;

const StyledProductLogo = styled(ProductLogo)`
  width: 100%;
`;

const GlobalStyles = createGlobalStyle`
  
  body, 
  html, #root {
    height: 100%;
  }
`;

export default UpdateDefaultPassword;
