export const courseCardQALabels = {
  saveButton: "save-button",
  unsaveButton: "unsave-button",
  registerButton: "register-button",
  registeredButton: "registered-button",
  missedButton: "missed-button",
  incompleteButton: "incomplete-button",
  completedButton: "completed-button",
  playButton: "play-button",
  resumeButton: "resume-button",
  playAgainButton: "play-again-button",
  buyButton: "buy-button",
  addToCartButton: "add-to-cart-button",
  rating: "course-rating",
  courseCard: "course-card-{{productId}}",
  courseCardName: "course-card-name",
  hideButton: "hide-button",
  creditsTag: "credits-tag",
  customLink: "custom-link",
  instructors: "course-card-instructors",
  format: "course-card-format",
  fos: "course-card-fields-of-study"
};

export const alreadyEarnedCreditModalQALabels = {
  okBtn: "tm-ok-btn",
  cancelBtn: "tm-cancel-btn",
  viewCompletionBtn: "view-completion-btn"
};
